// Make MainApp globally available
window.MainApp = false;

(function($) {
MainApp = {
    debug: false,

    init: function() {
        var app = this;

        $(window).load(function(){
            $('body').addClass('loaded');
        });

        $(document).ready(function() {
            $('body').addClass('ready');
             $(document).on("click", ".metro-item[data-metro-for]", function() {
            var e = $(this).attr("data-metro-for"),
                s = $(this).attr("data-metro-title");
            $("#metro-nav").hide(1), $("#metro-content").show(1), $("#metro-back").show(), $('.wheelsearch-tab[data-metro-content="' + e + '"]').show(1), $('.metro-title[data-metro-title-content="default"]').hide(1), $('.metro-title[data-metro-title-content="' + s + '"]').show(1)
        }), $(document).on("click", "#metro-back", function() {
            $("#metro-nav").show(1), $("#metro-content").hide(1), $("#metro-back").hide(), $(".wheelsearch-tab").hide(1), $(".metro-title").hide(1), $('.metro-title[data-metro-title-content="default"]').show(1)
        })
            app.common();
        });

       
    },

    common: function() {
        var app = this;

        // Do we have a touch device?
        function is_touch_device() {
            return ('ontouchstart' in window) ||
                (navigator.maxTouchPoints > 0) ||
                (navigator.msMaxTouchPoints > 0);
        }

        if(is_touch_device()) {
            $('body').addClass('touch');
        } else {
            $('body').addClass('no-touch');
        }

        // Hash scroll
        $(window).load(function(){
            var hashcontainer = $('#hash-scroll');
            if(hashcontainer.length > 0) {
                var hash = hashcontainer.data('hash');
                scrollToElements(hash);
            }
        });

        // Smooth Scroll Link
        $('body').on('click', 'a.smooth-scroll', function(event){
            event.preventDefault();
            this.scrollTo(this.hash);
        });
    },

    scrollTo: function(hash) {
        if(hash) {
            var hashel = $(hash);

            if(hashel.length > 0) {
                $('html,body').stop().animate({scrollTop:hashel.offset().top}, 500);
            }
        }
    },

    isIE: function() {
        return Object.hasOwnProperty.call(window, "ActiveXObject") && !window.ActiveXObject;
    }
}
MainApp.init();
})($);
